<template>
  <div class="Table mt-8">
    <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading="loading"
      hide-default-footer
    >


      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.filename"
            :class="($vuetify.breakpoint.xs)?'ma-0':'my-2'"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl=2
          >
            <v-card>
              <v-card-title class="ma-0 pa-0">
                <TableThumbnail
                  :item="item"
                  idName="ObjectId"
                  :type="item.contentType"
                  :change="toggleChart"
                  :free="true"
                  v-on:showContentEmit="showContent"
                  
                />
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text class="ma-0 pa-0 px-3 pb-2">
                 <v-row  class="ma-0  pa-0   grey--text  text--disabled text-right" height="5px" width="100%" style="font-size:13px; height:13px;">
                   <v-col xl=12 class="ma-0 pa-0 pr-1">{{contents[item.contentType].title}}</v-col>
                  </v-row>
                <v-row class="mx-0 ma-0 pa-0">
                 
                  <v-col lg=12 class="ma-0 pa-0">{{item.fileName}}</v-col>
                </v-row>
                <!-- <v-row class="ma-0 pa-0 caption font-weight-bold grey--text opacity text--disabled">{{contents[item.contentType].title}}</v-row> -->
                <v-row class="ma-0 mx-1 pa-0">
                  <v-col cols=9 lg=9 class="ma-0 pa-0">
                    {{item["fileSizeConvert"]}}
                  </v-col>

                  <v-spacer></v-spacer>
                 
                  <v-col cols=1 lg=1 class="mx-1 pa-0">
                    <v-tooltip bottom  >
                      <template v-slot:activator="{ on, attrs }"> 
                        <v-btn 
                          icon 
                          dense    
                          v-bind="attrs"
                          v-on="on"
                          class="ma-0 pa-0" 
                          @click="addContent(item)"
                           small>
                            <v-icon dense class="ma-0 pa-0">{{icons.plus}}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{contents[item.contentType].title}}に追加</span>
                    </v-tooltip>
                  </v-col>


             
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

    </v-data-iterator>
  </v-container>

      <DialogsShowContents
        ref="showContentsChild"
      />

      <v-dialog v-model="dialogAddContent" max-width="700px" loading="false" persistent >
      <DialogAddContent
        v-on:addContentEmit="addContentExe"
        v-on:closeEmit="close"
        :item="targetItem"
      />
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
    >

      <div v-if="!completeAddContent">
      コンテンツ追加中  
      <v-progress-circular
      indeterminate
      color="white"
    ></v-progress-circular>
      </div>
      <div v-else>追加しました</div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
  

<script>
import axios from "axios"
import { mdiFilePdfBox, mdiFileVideo, mdiImage, mdiMusic, mdiPlus, mdiVideo3d } from '@mdi/js';

const TableThumbnail = () => import('@/components/TableThumbnail.vue');
const DialogAddContent = () => import('@/components/DialogAddContent.vue');
const DialogsShowContents = () => import('@/components/DialogsShowContents.vue');


export default {
  name: 'ContentsTable',
  components :{
    TableThumbnail,
    DialogAddContent,
    DialogsShowContents
  },

  data () {
    return {
      icons:{"plus":mdiPlus},
      contentItem:{type:"object"},
      toggleChart:false,
      targetItem:null,
      dialogAddContent:false,
      snackbar:false,
      loading:false,
      completeAddContent:false,
      itemsPerPageArray: [4, 8, 12],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 12,
        sortBy: 'ObjectId',
        contentTarget:"object",
        contentTabs:{
          "3Dモデル":"Object",
          "画像":"Image",
          "PDF":"Pdf",
          "音声":"Sound",
          "動画":"Movie",
        },
        contents:{
          object:{
            type:"object",
            title:"3Dモデル",
            id:"objectId",
            icon:mdiVideo3d
          },
          image:{
            type:"image",
            title:"画像",
            id:"imageId" ,
            icon:mdiImage
          },
          pdf:{
            type:"pdf",
            title:"PDF",
            id:"pdfId",
            icon:mdiFilePdfBox
          },
          sound:{
            type:"sound",
            title:"音声",
            id:"soundId",
            icon:mdiMusic
          },
          movie:{
            type:"movie",
            title:"動画",
            id:"movieId",
            icon:mdiFileVideo
          },
        },
      
        items: [],
      }
    },
    
    watch:{
      contentTarget:function(){
        this.getData(false);
      },
      sortBy:function(){  
        this.reChart();
      },
      search:function(){  
        this.reChart();
      }
    },

    mounted(){
      this.getData(false);
    },

    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.headers.filter(key => key !== 'Name')
      },
      headers () {
      if(this.contentItem.type=="url"){
          return [
            {
              text: '名前',
              align: 'start',
              sortable: true,
              value: 'fileName',
              width: "30%",
            },
            {
              text: 'アップロード日',
              sortable: true,
              value: 'createTime',
              width: "15%",
            },
            {
              text: 'URL',
              sortable: true,
              value: 'url',
              width: "55%",
            },
            { text: '',
              sortable: false ,
              value: 'actions',
              width: "3%",
            },
          ]
        }
        else{
          return [

              'thumbnail',
              'fileName',
            'createTime',
             'fileSizeConvert',
           
          ]
        }
      },
    },
    methods: {
      getData: async function (reload) { 
        this.items = [];
        this.loading = true;
        let type = "free_content"
        const self = this;
        //読み込みを高速化するため少し遅延させる
        setTimeout(function(){
          let data = self.$store.state.user_model["free_content"];
          self.items = [];

          if(data != undefined  & data.length!=0  & !reload ){
            Object.keys(data).forEach(key =>{
               let item =  data[key];
              for(let i = 0 ; i < item.length; i++){
                item[i]["contentType"] = key;
                self.items.push(item[i]);
              }
            })
          }else{
            self.$store.dispatch("setContentInfo",[type,self.$store.state.auth.login]).then(() => {
              data = self.$store.state.user_model["free_content"];
              Object.keys(data).forEach(key =>{
                let item =  data[key];
                for(let i = 0 ; i < item.length; i++){
                  item[i]["contentType"] = key;
                  self.items.push(item[i]);
                }
                
              })
              setTimeout(self.reChart,500);

            }).catch(reason => {   
                console.log(reason)   
                self.loading = false;
          });
          }
          
        },10)
        await setTimeout(this.reChart,500);
      },

      addContent: function(item){
        this.completeAddContent = false;
        this.dialogAddContent = true;
        this.targetItem = item;
        // axios({
        //   method: "PUT",
        //   url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${item.contentType}s/free_contents`,
        //   headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
        //   data:{'sampleId':item[`${item.contentType}Id`]}
        // }).then((response) => {
        //   console.log(response)
        //   let data = response.data.results;
        //   this.uploadExe(data,item.contentType)

        // }).catch((error) =>{
        //   console.log(error)
        // });
      },

      addContentExe: function(item){
        this.snackbar = true;
        this.dialogAddContent = false;
        axios({
          method: "PUT",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${item.contentType}s/free_contents`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
          data:{'sampleId':item[`${item.contentType}Id`]}
        }).then((response) => {
          console.log(response)
          let data = response.data.results;
          this.uploadExe(data,item.contentType)

        }).catch((error) =>{
          console.log(error)
        });
      },

      
    //テーブルへのアップロードされたアイテムの追加
    uploadExe(item,type){

      let fileSize =  item.fileSize
      let fileSizeConvert = (Number(fileSize)).toFixed(3) + " MB";
      let fileName = item.fileName;
      let createTime = item.createTime;
      let thumbnail = item.thumbnail;
      let id = item[this.contents[type]['id']];

      this.$store.state.user_model.userData.total.usage += Number(fileSize)/1024;

      this.$store.state.user_model[type].push({  
        thumbnail:thumbnail,
        fileSize:fileSize,
        fileSizeConvert:fileSizeConvert,
        fileName:fileName,
        createTime:createTime,
        [this.contents[type]['id']]:id})
      this.completeAddContent = true;
      this.close()

    },

      //サムネイル、オブジェクトの再描写
      reChart() {        
        this.toggleChart = !this.toggleChart;
      },

      showContent(item,show){

        this.$refs.showContentsChild.downloadContent(item,show)
      },

      close(){
        this.dialogAddContent = false;
      },

      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
         setTimeout(this.reChart,10);
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1;
        setTimeout(this.reChart,10);
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },
  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color:#4ECDC4;
}

.text-start {
  padding: none;
}

v-data-table >>> div > table {
  border-spacing: 0 0.5rem;
}

.search-content{
  background-color: #42b983;
}


.v-snack__wrapper {
    max-width: none;
}

.v-snack__content {
    padding:2px !important;
}

.low-grey:hover {
  background-color: rgba(211, 211, 211, 0.15) !important;
}


audio::-webkit-media-controls-play-button,
    audio::-webkit-media-controls-panel {
    background-color:  white;
    color: rgb(142, 142, 142); 
    opacity: 0.8;
    }

    .v-data-footer {
        justify-content: center;
        padding-bottom: 8px;

        & .v-data-footer__select {
            margin: 0 auto;
        }

        & .v-data-footer__pagination {
            width: 100%;
            margin: 0;
        }
    }

    .h-0{
      height: 0px;
    }

    .h-50{
      height: 50px;
    }

    .v-application--is-ltr .v-data-footer__select .v-select {
        margin: 5px 0 5px 13px;
    }
    .v-application--is-rtl .v-data-footer__select .v-select {
        margin: 5px 13px 5px 0;
    }

    .v-input--selection-controls__ripple {
      margin:0  !important; ;
    }

    th {
      margin:0px !important;
    }


</style>
